<template>
    <div class="upload-container">
        <el-divider content-position="left">上传附件请预先配置好服务端</el-divider>
        <vab-upload ref="vabUpload"
                    title="模拟上传"
                    action="http://127.0.0.1/File/UpLoad"
                    name="file"
                    accept="image/*"
                    :limit="2"
                    :size="1"
                    v-on:finish="save"></vab-upload>
       
        <div class="goods-list-container">
            <vab-query-form>
                <vab-query-form-left-panel :span="24">
                    <el-form ref="form" :inline="true">
                        <el-form-item>
                            <el-button type="primary" @click="handleShow({ key: 'value' })">
                                模拟上传
                            </el-button>
                        </el-form-item>
                    </el-form>
                </vab-query-form-left-panel>
            </vab-query-form>

            <el-row :gutter="20">
                <el-col v-for="(item, index) in list"
                        :key="index"
                        :xs="24"
                        :sm="8"
                        :md="8"
                        :lg="8"
                        :xl="6">
                    <el-card :body-style="{ padding: '0px' }" shadow="hover">
                        <div class="goods-list-card-body">
                            <div class="goods-list-image-group">
                                <img :src="item.url" class="goods-list-image" />
                            </div>
                            <div class="goods-list-title">{{ item.name }}</div>

                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>

   
</template>

<script>
    import VabUpload from '@/components/VabUpload';

    export default {
        name: 'Upload',
        components: {
            VabUpload
        },
        data() {
            return {
                list: []
            }
        },
        methods: {
            handleShow(data) {
                this.$refs['vabUpload'].handleShow(data);
            },
            save: function (data) {
                //上传成功的附件列表
                this.list = data;
                console.log(JSON.stringify(data));
            }
        },
    }
</script>


<style lang="scss" scoped>
    .goods-list-container {
        .goods-list-card-body {
            position: relative;
            text-align: center;
            cursor: pointer;

            .goods-list-tag-group {
                position: absolute;
                top: 10px;
                right: 5px;
                z-index: 9;
            }

            .goods-list-image-group {
                height: 400px;
                overflow: hidden;

                .goods-list-image {
                    width: 100%;
                    height: 400px;
                    transition: all ease-in-out 0.3s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .goods-list-title {
                margin: 8px 0;
                font-size: 16px;
                font-weight: bold;
            }

            .goods-list-description {
                font-size: 14px;
                color: #808695;
            }

            .goods-list-price {
                margin: 8px 0;
                font-size: 14px;
                color: $base-color-orange;

                s {
                    color: #c5c8ce;
                }
            }
        }
    }
</style>
