<template>
    <div class="roleManagement-container">

        <!-- 基础表格模块 -->
        <vab-query-form>
            <vab-query-form-left-panel :span="12">
                
            
                <el-form :inline="true" :model="queryForm" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model.trim="queryForm.name"
                                  placeholder="请输入查询条件"
                                  clearable />
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="queryData">
                            查询
                        </el-button>

                        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
                            添加
                        </el-button>
                    </el-form-item>
                </el-form>
            </vab-query-form-left-panel>
        </vab-query-form>
        <el-table v-loading="listLoading"
                  border
                  :data="list"
                  :element-loading-text="elementLoadingText"
                  @selection-change="setSelectRows">
            <!--<el-table-column show-overflow-tooltip type="selection"></el-table-column>-->
            <el-table-column show-overflow-tooltip label="操作" width="350">
                <template #default="{ row }">
                    <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
                    <el-button type="primary" @click="permission(row)">授权模块</el-button>
                    <el-button v-if="row.code!='admin'" type="primary" @click="bindUserRel(row)">用户关系</el-button>
                    <el-button v-if="row.code!='admin'" type="danger" @click="handleDelete(row)">删除</el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="60px"
                             prop="id"
                             align="center"
                             label="编号"></el-table-column>
            <el-table-column show-overflow-tooltip
                             prop="name"
                             label="组名称"></el-table-column>

            <el-table-column show-overflow-tooltip
                             prop="code"
                             label="权限码"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             label="状态">
                <template #default="{ row }">
                    <el-tag v-if="row.isDisable" type="info">禁用</el-tag>
                    <el-tag v-else type="success">启用</el-tag>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             prop="desc"
                             label="描述"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="180px"
                             prop="creationTime"
                             label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.creationTime| DateFormat}}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       :current-page="queryForm.pageIndex"
                       :page-size="queryForm.pageSize"
                       :layout="layout"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
        <edit ref="edit" @fetch-data="fetchData"></edit>




        <!-- 授权菜单模块 -->
        <el-dialog :visible.sync="permissionDialogVisible" 
                   :title="permissionDialogType==='edit'?'编辑授权':'新增授权'" 
                   width=500px>
            <el-form :model="group" label-width="80px" label-position="left">
                <el-form-item label="组权限">
                    <span>{{group.name}}</span>
                </el-form-item>
                <el-form-item label="权限码">
                    <el-tag type="success">{{group.code}}</el-tag>
                </el-form-item>
                <el-form-item label="菜单模块">
                    <el-tree ref="tree"
                             :default-expand-all="true"
                             :check-strictly="checkStrictly"
                             :data="menus"
                             :props="defaultProps"
                             show-checkbox
                             node-key="id"
                             class="permission-tree" />
                </el-form-item>
            </el-form>
            <div style="text-align:right;">
                <el-button type="danger" @click="cancelSelected()">全取消</el-button>
                <el-button type="primary" @click="confirmPermission(group.id)">保存</el-button>
            </div>
        </el-dialog>

        <!-- 设置用户模块 -->
        <el-dialog :visible.sync="userRelDialogVisible" :title="userRelTitle" width=600px>
            <vab-query-form>
                <el-form :inline="true" :model="userQueryForm" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model.trim="userQueryForm.name"
                                  placeholder="请输入用户名"
                                  clearable />
                    </el-form-item>

                    <!--<el-form-item>
                        <el-select v-model="userQueryForm.permitedType" clearable placeholder="是否授权">
                            <el-option v-for="item in userPermitedType"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id" />
                        </el-select>
                    </el-form-item>-->

                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="queryUserData">
                            查询
                        </el-button>
                    </el-form-item>
                </el-form>
            </vab-query-form>

            <el-table :data="userList" ref="userTable">
                <el-table-column show-overflow-tooltip label="操作" width="100" align="center">
                    <template #default="{ row }">
                        <el-button v-if="row.isPermited==1" type="danger" @click="removeUserRel(row)">解除</el-button>
                        <el-button v-if="row.isPermited==0" type="primary" @click="addUserRel(row)">授权</el-button>
                    </template>
                </el-table-column>
                <!--<el-table-column show-overflow-tooltip type="selection"></el-table-column>-->
                <el-table-column show-overflow-tooltip
                                 width="100px"
                                 label="是否授权"
                                 align="center">
                    <template #default="{ row }">

                        <el-tag v-if="row.isPermited==1" type="success">是</el-tag>
                        <el-tag v-else type="info">否</el-tag>
                    </template>
                </el-table-column>

                <el-table-column show-overflow-tooltip
                                 width="60px"
                                 prop="id"
                                 label="id"></el-table-column>
                <el-table-column show-overflow-tooltip
                                 width="100px"
                                 prop="name"
                                 label="用户名"></el-table-column>
                <el-table-column show-overflow-tooltip
                                 width="120px"
                                 prop="mobile"
                                 label="手机"></el-table-column>
                <el-table-column show-overflow-tooltip
                                 width="80px"
                                 label="状态">
                    <template #default="{ row }">
                        <el-tag v-if="row.isDisable" type="info">禁用</el-tag>
                        <el-tag v-else type="success">启用</el-tag>
                    </template>
                </el-table-column>
                <!--<el-table-column show-overflow-tooltip
                                 prop="creationTime"
                                 label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.creationTime| DateFormat}}
                    </template>
                </el-table-column>-->
            </el-table>

            <el-pagination background
                           :current-page="userQueryForm.pageIndex"
                           :page-size="userQueryForm.pageSize"
                           :layout="layout"
                           :total="userTotal"
                           @size-change="handleUserSizeChange"
                           @current-change="handleUserCurrentChange"></el-pagination>

        </el-dialog>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { permitByModules, getModulesByGroupId } from '@/api/menu';
    import { getUserGrop, DeleteUserGrop, getUserList, addUserRel, removeUserRel } from '@/api/user';
    import Edit from './components/UserGroupEdit';
    const defaultGroup = {
        id: 0,
        code: '',
        name: '',
        description: '',
        routes: []
    }

    export default {
        name: 'UserGroup',
        components: { Edit },
        data() {
            return {
                permissionDialogVisible: false,
                permissionDialogType: 'edit',
                checkStrictly: false,
                group: Object.assign({}, defaultGroup),
                defaultProps: {
                    children: 'children',
                    label: function (data) {
                        return data.meta.title
                    },
                },


                userGroupId: 0,
                userRelDialogVisible: false,
                userList: null,
                userQueryForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    //permitedType: null,
                    name: '',
                },
                userTotal: 0,
                userPermitedType: [{ id: 0, name: '否' }, { id: 1, name: '是' }],
                userRelTitle: '用户关系',

                list: null,
                listLoading: true,
                layout: 'total, sizes, prev, pager, next, jumper',
                total: 0,
                selectRows: '',
                elementLoadingText: '正在加载...',
                queryForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    name: '',
                },
            }
        },
        created() {
            this.fetchData();
        },
        computed: {
            ...mapGetters({
                getMenus: 'menu/menus'
            }),
            menus() {
                return this.getMenus;
            }
        },
        methods: {
            /* 设置用户关系 */
            async addUserRel(row) {
                var add = { userGroupId: this.userGroupId, userId: row.id };
                const { isSucceed, message } = await addUserRel(add);
                this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                this.fetchUserData();
            },
            async removeUserRel(row) {
                var del = { groupId: this.userGroupId, userId: row.id };
                const { isSucceed, message } = await removeUserRel(del);
                this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                this.fetchUserData();
            },
            async fetchUserData() {
                const { isSucceed, message, result, total } = await getUserList(this.userQueryForm);
                if (!isSucceed) return this.$baseMessage(message, 'error');
                let userData = [];
                result.forEach((row) => {
                    row['isPermited'] = 0;
                    if (row.groups.some(s => s.userGroupId == this.userGroupId)) {
                        row['isPermited'] = 1;
                    }
                    userData.push(row);
                });
                this.userList = userData;
                this.userTotal = total;
            },
            bindUserRel(row) {
                this.userGroupId = row.id;
                this.fetchUserData();
                this.userRelDialogVisible = true;
                this.userRelTitle = `组[${row.name}]用户关系`;
            },
            queryUserData() {
                this.userQueryForm.pageIndex = 1;
                this.fetchUserData();
            },
            handleUserSizeChange(val) {
                this.userQueryForm.pageSize = val;
                this.fetchUserData();
            },
            handleUserCurrentChange(val) {
                this.userQueryForm.pageIndex = val;
                this.fetchUserData();
            },

            /* 设置授权关系 */
            cancelSelected() {
                this.$nextTick(() => { this.$refs.tree.setCheckedKeys([]) });
            },
            setSelectRows(val) {
                this.selectRows = val;
            },
            async permission(row) {
                this.permissionDialogType = 'edit';
                this.permissionDialogVisible = true;
                //预先置空选中项
                this.$nextTick(() => { this.$refs.tree.setCheckedKeys([]) });
                this.group = row;
                const { isSucceed, message, result } = await getModulesByGroupId({ groupId: this.group.id });
                if (!isSucceed) return this.$baseMessage(message, 'error');
                //重新设置选中项
                this.$nextTick(() => {
                    result.forEach(item => {
                        this.$refs.tree.setChecked(item.id, true, false);
                    })
                });
            },
            async confirmPermission(id) {
                var checkedKeys = this.getCheckedKeys(this.menus, this.$refs.tree.getCheckedKeys());
                this.$baseConfirm(`确定保存?`, null, async () => {
                    const { isSucceed, message } = await permitByModules({ groupId: id, modules: checkedKeys });
                    this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                    this.fetchData();
                    this.permissionDialogVisible = false;
                });
            },
            /* 用户组操作 */
            handleEdit(row) {
                if (row.id) {
                    this.$refs['edit'].showEdit(row);
                } else {
                    this.$refs['edit'].showEdit();
                }
            },
            handleDelete(row) {
                if (row.id) {
                    this.$baseConfirm(`你确定要删除当前项吗`, '提示', async () => {
                        const { isSucceed, message } = await DeleteUserGrop({ groupId: row.id });
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        this.fetchData();
                    });
                }
            },
            handleSizeChange(val) {
                this.queryForm.pageSize = val;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.queryForm.pageIndex = val;
                this.fetchData();
            },
            queryData() {
                this.queryForm.pageIndex = 1;
                this.fetchData();
            },
            async fetchData() {
                this.listLoading = true;
                const { result, total } = await getUserGrop(this.queryForm);
                this.list = result;
                this.total = total;
                this.listLoading = false;
            },
            getCheckedKeys(data, keys, key = 'id') {
                var res = [];
                recursion(data, false);
                return res;
                // arr -> 树形总数据
                // keys -> getCheckedKeys获取到的选中key值
                // isChild -> 用来判断是否是子节点
                function recursion(arr, isChild) {
                    var aCheck = [];
                    for (var i = 0; i < arr.length; i++) {
                        var obj = arr[i];
                        aCheck[i] = false;
                        if (obj.children) {
                            aCheck[i] = recursion(obj.children, true) ? true : aCheck[i];
                            if (aCheck[i]) {
                                res.push(obj[key]);
                            }
                        }
                        for (var j = 0; j < keys.length; j++) {
                            if (obj[key] == keys[j]) {
                                aCheck[i] = true;
                                if (res.indexOf(obj[key]) == -1) {
                                    res.push(obj[key]);
                                }
                                break;
                            }
                        }
                    }
                    if (isChild) {
                        return aCheck.indexOf(true) != -1;
                    }
                }
            },
        },
    }
</script>


