<template>
        <div class="app-main-height">
            <el-alert title="岗位编辑" type="info" center show-icon :closable="false"></el-alert>
            <el-steps :space="500" :active="activeIndex - 0" finish-status="success" align-center>
                <el-step title="基本信息"></el-step>
                <el-step title="详情信息"></el-step>
                <el-step title="轮播图片"></el-step>
            </el-steps>
            <el-tabs v-model="activeIndex" :tab-position="'left'" @tab-click="tabClick">
                <!--1、基本信息-->
                <el-tab-pane label="基本信息" name="1">
                    <el-form ref="form1" :model="form" :rules="rules" label-width="80px">
                        <!-- 岗位编号与岗位名称 -->
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位编号" prop="code">
                                    <el-input v-model.trim="form.code" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位名称" prop="name">
                                    <el-input v-model.trim="form.name" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 岗位工价与结算方式 -->
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位工价" prop="wages">
                                    <el-input type="number" v-model.trim="form.wages" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="结算方式" prop="wagesUnit">
                                    <el-select v-model="form.wagesUnit" placeholder="请选择" clearable filterable>
                                        <el-option v-for="item in salaryUnit"
                                                   :key="item.value"
                                                   :label="item.name"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 学历要求与岗位类别 -->
                        <el-row :gutter="10">
                            <!--<el-col :xs="24" :sm="12" :md="12" :lg="12">
            <el-form-item label="岗位分类" prop="categoryId">
                <el-cascader ref="categoryIdCascader"
                             v-model="form.categoryId"
                             :options="cateList"
                             :props="cateProps"
                             clearable></el-cascader>
            </el-form-item>
        </el-col>-->
                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="学历要求" prop="educationId">
                                    <el-select v-model="form.educationId" placeholder="请选择" clearable filterable>
                                        <el-option v-for="item in educationList"
                                                   :key="item.id"
                                                   :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>

                                </el-form-item>
                            </el-col>


                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位类别" prop="typeId">
                                    <el-select v-model="form.typeId" placeholder="请选择" clearable filterable>
                                        <el-option v-for="item in typeList"
                                                   :key="item.id"
                                                   :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>

                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 岗位月薪与是否下架 -->
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位月薪" prop="salary">
                                    <el-input v-model.trim="form.salary" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="是否下架">
                                    <el-switch v-model="form.isDisable"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 性别限制与公司名称 -->
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="性别限制" prop="sexLimit">
                                    <el-select v-model="form.sexLimit" placeholder="请选择" clearable filterable>
                                        <el-option v-for="item in sexLimits"
                                                   :key="item.value"
                                                   :label="item.name"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="公司名称" prop="company">
                                    <el-input v-model.trim="form.company"></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>

                        <!-- 企业位置与详细地址 -->
                        <el-row :gutter="10">
                            <!--<el-col :xs="24" :sm="12" :md="12" :lg="12">
            <el-form-item label="企业位置" prop="latitude">
                <span>{{form.longitude}}</span>
                <span>-{{form.latitude}}</span>
                <el-button type="text" style="margin-left:5px" @click="selectMap">位置选择</el-button>
            </el-form-item>
        </el-col>-->
                            <el-col :xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="企业位置" prop="cityIds">
                                    <el-cascader ref="cityCascader"
                                                 v-model="form.cityIds"
                                                 :options="cityList"
                                                 :props="cityProps"
                                                 @change="cityChange"
                                                 clearable></el-cascader>
                                </el-form-item>
                            </el-col>

                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="详细地址" prop="address">
                                    <el-input v-model.trim="form.address"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>


                        <!-- 岗位佣金与岗位排序 -->
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位佣金" prop="commision">
                                    <el-input type="number" v-model.trim="form.commision" autocomplete="off" minValue="1"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位排序" prop="sort">
                                    <el-input type="number" v-model.trim="form.sort" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 岗位主图 -->
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="12" :lg="12">
                                <el-form-item label="岗位主图" prop="image">
                                    <el-button type="primary" @click="uploadShow({ key: 'value' })">
                                        上传岗位主图
                                    </el-button>
                                </el-form-item>
                                <el-form-item>
                                    <img class="img" :src="form.image" />
                                    <vab-upload ref="vabUpload"
                                                title="上传岗位主图"
                                                action="/File/UpLoad"
                                                name="file"
                                                accept="image/*"
                                                :limit="1"
                                                :size="1"
                                                v-on:finish="upload"></vab-upload>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <!-- 岗位标签 -->
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="24" :md="24" :lg="24">
                                <el-form-item label="岗位标签">
                                    <el-input type="text" v-model="tag" placeholder="请输入岗位标签" style="width:50%; margin-right:5px"></el-input>
                                    <el-button type="primary" @click="addtag">添加标签</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col ::xs="24" :sm="24" :md="24" :lg="24">
                                <el-form-item label="">
                                    <el-tag v-for="(item) in form.labels" :key="item.name" closable :disable-transitions="false" @close="delTag(item)">
                                        {{ item.name }}
                                    </el-tag>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="">
                            <el-button type="primary" @click="next(1)">下一步</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <!--2、详情描述-->
                <el-tab-pane label="岗位详情" name="2">
                    <div class="editor-container">
                        <el-form ref="form2" :model="form.detail" :rules="rules.detail" label-width="80px">
                            <!-- 待遇说明 -->
                            <!--<el-row>
                                <el-col ::xs="24" :sm="24" :md="24" :lg="24">
                                    <el-form-item label="待遇说明" prop="salaryDesc">
                                        <markdown-editor v-model="form.detail.salaryDesc" height="200px" />
                                    </el-form-item>
                                </el-col>
                            </el-row>-->
                            <!-- 职位描述 -->
                            <!--<el-row>
                                <el-col ::xs="24" :sm="24" :md="24" :lg="24">
                                    <el-form-item label="职位描述" prop="desc">
                                        <markdown-editor v-model="form.detail.desc" height="150px" />
                                    </el-form-item>
                                </el-col>
                            </el-row>-->
                            <!-- 企业介绍 -->
                            <!--<el-row>
                                <el-col ::xs="24" :sm="24" :md="24" :lg="24">
                                    <el-form-item label="企业介绍" prop="companyIntroduction">
                                        <markdown-editor v-model="form.detail.companyIntroduction" height="150px" />
                                    </el-form-item>
                                </el-col>
                            </el-row>-->
                            <!-- 入职要求 -->
                            <!--<el-row>
                                <el-col ::xs="24" :sm="24" :md="24" :lg="24">
                                    <el-form-item label="入职要求" prop="requirements">
                                        <markdown-editor v-model="form.detail.requirements" height="150px" />
                                    </el-form-item>
                                </el-col>
                            </el-row>-->


                            <!-- 岗位详情 -->
                            <el-row>
                                <el-col ::xs="24" :sm="24" :md="24" :lg="24">
                                    <el-form-item label="岗位详情" prop="desc">
                                        <markdown-editor v-model="form.detail.desc" height="600px" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-form-item label="">
                                <el-button type="warning" @click="back(1)">上一步</el-button>
                                <el-button type="primary" @click="next(2)">下一步</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
                <!--2、轮播图片-->
                <el-tab-pane label="岗位轮播" name="3">
                    <el-form>
                        <el-form-item label="岗位轮播：">
                            <el-button type="primary" @click="carouselUploadShow({ key: 'value' })">上传轮播</el-button>
                            <vab-upload ref="carouselUpload"
                                        title="上传轮播"
                                        action="/File/UpLoad"
                                        name="file"
                                        accept="image/*"
                                        :limit="10"
                                        :size="2"
                                        v-on:finish="carouselUpload"></vab-upload>
                        </el-form-item>

                        <el-form-item label="">
                            <el-table ref="imgTable"
                                      :data="form.carousels"
                                      height="400px"
                                      border>
                                <el-table-column show-overflow-tooltip label="序号" width="80" align="center">
                                    <template #default="scope">
                                        {{ scope.$index + 1 }}
                                    </template>
                                </el-table-column>

                                <el-table-column show-overflow-tooltip
                                                 prop="url"
                                                 label="文件路径"></el-table-column>

                                <el-table-column show-overflow-tooltip
                                                 label="文件名称"
                                                 prop="name"></el-table-column>

                                <el-table-column show-overflow-tooltip label="图片">
                                    <template #default="{ row }">
                                        <el-image :preview-src-list="carouselList"
                                                  :src="row.url"></el-image>
                                    </template>
                                </el-table-column>

                                <el-table-column show-overflow-tooltip label="操作" width="100px">
                                    <template #default="{ row }">
                                        <el-button type="danger" @click="deleteCarousel(row)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="warning" @click="back(2)">上一步</el-button>
                            <el-button type="primary" icon="el-icon-edit-outline" @click="save">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
            <map-view ref="mapView" v-on:finish="location"></map-view>
        </div>
</template>

<script>
    import { salaryUnit, sexLimits, educationList } from '@/config/data';
    import { getCities } from '@/api/city';
    import VabUpload from '@/components/VabUpload';
    import { GetCategories, getPosition, GetTypes, addPosition, editPosition } from '@/api/position';
    import { formatToTree } from '@/utils';
    import MapView from './components/Map';
    import MarkdownEditor from '@/components/MarkdownEditor'



    export default {
        name: 'PositionDetail',
        components: { VabUpload, MapView, MarkdownEditor },
        data() {
            return {
                tag: '',
                title: '',
                positionFormVisible: false,
                activeIndex: '1',
                salaryUnit: salaryUnit,
                //学历要求
                educationList: educationList,
                //岗位类别
                typeList: [],
                //岗位分类
                cateList: [],
                //性别限制
                sexLimits: sexLimits,
                //城市列表
                cityList: [],
                cateProps: {
                    expandTrigger: 'hover',
                    value: 'id',
                    label: 'name',
                    children: 'children'
                },
                cityProps: {
                    expandTrigger: 'hover',
                    value: 'id',
                    label: 'name',
                    children: 'children'
                },
                form: {
                    id: 0,
                    //基本信息
                    code: '',
                    name: '',
                    sexLimit: null,
                    categoryId: null,
                    typeId: null,
                    educationId: null,
                    sort: null,
                    isDisable: true,
                    image: '',
                    wages: '',
                    wagesUnit: null,
                    salary: '',
                    commision: null,
                    company: '',
                    //位置信息
                    cityIds: [],
                    provinceId: null,
                    cityId: null,
                    longitude: null,
                    latitude: null,
                    address: '',
                    //岗位轮播
                    carousels: [],
                    //岗位标签
                    labels: [],
                    //岗位详情
                    detail: {
                        desc: '',

                        salaryDesc: '',
                        requirements: '',
                        companyIntroduction: '',
                    }
                },
                rules: {
                    code: [{ required: true, message: '请输入岗位编号', trigger: 'blur' }],
                    name: [{ required: true, message: '请输入岗位名称', trigger: 'blur' }],
                    image: [{ required: true, message: '请上传岗位主图', trigger: 'blur' }],
                    typeId: [{ required: true, message: '请选择岗位类别', trigger: 'blur' }],
                    categoryId: [{ required: true, message: '请选择岗位分类', trigger: 'blur' }],
                    educationId: [{ required: true, message: '请选择学历要求', trigger: 'blur' }],
                    wages: [
                        { required: true, message: '请输入岗位工价', trigger: 'blur' },
                        { pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: "请输入正确的岗位工价", trigger: "change" }
                    ],
                    commision: [
                        { required: true, message: '请输入岗位佣金', trigger: 'blur' },
                        { pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: "请输入正确的岗位佣金", trigger: "change" }
                    ],
                    company: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
                    wagesUnit: [{ required: true, message: '请选择工价单位', trigger: 'blur' }],
                    salary: [{ required: true, message: '请输入岗位月薪', trigger: 'blur' }],
                    cityIds: [{ required: true, message: '请选择岗位城市', trigger: 'blur' }],
                    longitude: [{ required: true, message: '请输入岗位经度', trigger: 'blur' }],
                    latitude: [{ required: true, message: '请输入岗位纬度', trigger: 'blur' }],
                    address: [{ required: true, message: '请输入岗位具体位置', trigger: 'blur' }],
                    sort: [{ required: true, message: '请输入岗位排序', trigger: 'blur' }],
                    sexLimit: [{ required: true, message: '请选择性别限制', trigger: 'blur' }],
                    labels: [{ required: true, message: '请选择岗位标签', trigger: 'blur' }],

                    detail: {
                        desc: [{ required: true, message: '请输入职位详情', trigger: 'blur' }],

                        salaryDesc: [{ required: true, message: '请输入待遇说明', trigger: 'blur' }],
                        requirements: [{ required: true, message: '请输入入职要求', trigger: 'blur' }],
                        companyIntroduction: [{ required: true, message: '请输入企业介绍', trigger: 'blur' }],
                    }
                },
            }
        },
        async created() {
            this.getTypes();
            this.getCities();

            this.form.id = this.$route.query.id;
            this.getDetail();
        },
        computed: {
            carouselList() {
                let list = [];
                this.form.carousels.forEach((item) => {
                    list.push(item.url);
                });
                return list;
                //console.log(list);
            }
        },

        methods: {
            /* 城市列表 */
            async getCities() {
                const { isSucceed, message, result } = await getCities();
                if (!isSucceed) return this.$baseMessage(message, 'error');
                let tree = this.renderTreeData(formatToTree(result));
                //console.log('[getCities]' + JSON.stringify(result));
                this.cityList = tree;
            },
            cityChange: function (v) {
                //console.log('[cityChange]' + v);
            },
            /* 岗位分类 */
            async getCategories() {
                const { isSucceed, message, result } = await GetCategories();
                if (!isSucceed) return this.$baseMessage(message, 'error');
                let tree = this.renderTreeData(formatToTree(result));
                //console.log('[getCategories]' + JSON.stringify(result));
                this.cateList = tree;
            },
            delTag(tag) {
                for (var i in this.form.labels) {
                    if (this.form.labels[i].name == tag.name) {
                        this.form.labels.splice(i, 1);
                        break;
                    }
                }
            },
            /* 添加标签 */
            addtag() {
                if (this.tag) {
                    this.form.labels.push({ 'name': this.tag });
                }
            },
            /* 企业位置 */
            selectMap() {
                this.$refs['mapView'].openDialog();
            },
            location: function (data) {
                if (data) {
                    this.form.address = data.address;
                    this.form.latitude = data.coordinate.lat.toString();
                    this.form.longitude = data.coordinate.lng.toString();
                }
            },
            /* 保存 */
            save() {
                if (this.form.carousels.length == 0) return this.$baseMessage('请上传轮播图', 'error');
                this.$refs['form1'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.cityIds instanceof Array) {
                        this.form.provinceId = this.form.cityIds[0];
                        this.form.cityId = this.form.cityIds[1];
                    }
                    this.form.detail.desc = this.form.detail.desc.replace(/\n/g, "<br>");
                  
                    const { isSucceed, message } = this.form.id === 0 ? await addPosition(this.form) : await editPosition(this.form);
                    if (!isSucceed) return this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                    this.$baseMessage('操作成功', (isSucceed ? 'success' : 'error'));
                    if (this.form.id === 0) {
                        await this.$store.dispatch('tabsBar/delRoute', this.$route);
                        this.$router.go(-1);
                    } 
                })
            },
            tabClick(tab, event) {
                this.activeIndex = tab.name;
            },
            back(step) {
                this.activeIndex = step.toString();
            },
            next(step) {
                this.$refs[`form${step}`].validate(async (valid) => {
                    if (!valid) return false;
                    this.activeIndex = (parseInt(step) + 1).toString();
                })
            },
            handleSee() {
                this.dialogTableVisible = true;
            },
            uploadShow(data) {
                this.$refs['vabUpload'].handleShow(data);
            },
            upload: function (data) {
                if (data.length > 0) {
                    this.form.image = data[0].url;
                }
            },
            /* 商品轮播 */
            carouselUploadShow: function (data) {
                this.$refs['carouselUpload'].handleShow(data);
            },
            carouselUpload: function (data) {
                //console.log('[data]' + JSON.stringify(data));
                if (data.length > 0) {
                    this.form.carousels = this.form.carousels.concat(data);
                }
            },
            deleteCarousel(row) {
                this.form.carousels = this.form.carousels.filter(s => {
                    return s.url.indexOf(row.url) == -1;
                });
            },
            /* 岗位类别 */
            async getTypes() {
                const { isSucceed, message, result } = await GetTypes();
                if (!isSucceed) return this.$baseMessage(message, 'error');
                this.typeList = result;

            },
            renderTreeData(data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].children.length < 1) {
                        data[i].children = undefined;
                    } else {
                        this.renderTreeData(data[i].children);
                    }
                }
                return data;
            },

            async getDetail() {
                if (this.form.id != 0) {
                    const { isSucceed, message, result } = await getPosition({ id: this.form.id });
                    if (!isSucceed) return this.$baseMessage(message, 'error');
                    if (result.detail == null) delete result.detail;
                    this.form = Object.assign(this.form, result);
                    this.form.cityIds = [this.form.provinceId, this.form.cityId];
                }
            },
        }
    }

</script>

<style lang="scss" scoped>
    .el-steps {
        margin: 15px 0;
    }

    .el-tab-pane {
        margin-right: 25px;
    }

    .el-step_title {
        font-size: 13px;
    }

    .img {
        width: 100%;
        max-width: 250px;
    }

</style>
