<template>
    <div class="icons-container"> 
        <el-tabs type="border-card">
            <el-tab-pane label="Vab Icons">
                <div class="grid">
                    <div v-for="item of vabIcons" :key="item" @click="handleClipboard(generateVabIconCode(item),$event)">
                        <el-tooltip placement="top">
                            <div slot="content">
                                {{ generateVabIconCode(item) }}
                            </div>
                            <div class="icon-item">
                                <vab-icon :icon="['fas', item]" />
                                <span>{{ item }}</span>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane label="Icons">
                <div class="grid">
                    <div v-for="item of svgIcons" :key="item" @click="handleClipboard(generateIconCode(item),$event)">
                        <el-tooltip placement="top">
                            <div slot="content">
                                {{ generateIconCode(item) }}
                            </div>
                            <div class="icon-item">
                                <svg-icon :icon-class="item" class-name="disabled" />
                                <span>{{ item }}</span>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Element-UI Icons">
                <div class="grid">
                    <div v-for="item of elementIcons" :key="item" @click="handleClipboard(generateElementIconCode(item),$event)">
                        <el-tooltip placement="top">
                            <div slot="content">
                                {{ generateElementIconCode(item) }}
                            </div>
                            <div class="icon-item">
                                <i :class="'el-icon-' + item" />
                                <span>{{ item }}</span>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import clipboard from '@/utils/clipboard'
    import elementIcons from '@/mock/element-icons'
    import svgIcons from '@/mock/svg-icons'
    import vabIcons from '@/mock/vab-icon'

    export default {
        name: 'Icons',
        data() {
            return {
                svgIcons,
                elementIcons,
                vabIcons
            }
        },
        methods: {
            generateIconCode(symbol) {
                return `<svg-icon icon-class="${symbol}" />`
            },
            generateElementIconCode(symbol) {
                return `<i class="el-icon-${symbol}" />`
            },
            generateVabIconCode(symbol) {
                return `<vab-icon :icon="['fas', '${symbol}']"></vab-icon>`;
            },
            handleClipboard(text, event) {
                clipboard(text, event)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .icons-container {
        margin: 10px 20px 0;
        overflow: hidden;

        .grid {
            position: relative;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        }

        .icon-item {
            margin: 20px;
            height: 85px;
            text-align: center;
            width: 100px;
            float: left;
            font-size: 30px;
            color: #24292e;
            cursor: pointer;
        }

        span {
            display: block;
            font-size: 16px;
            margin-top: 10px;
        }

        .disabled {
            pointer-events: none;
        }
    }
</style>
