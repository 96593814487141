<template>
    <div class="Category-container">
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <!--<vab-query-form>
                    <vab-query-form-top-panel :span="12">
                        <el-button icon="el-icon-plus" type="primary" @click="handleAdd(null,'Root')">
                            添加城市
                        </el-button>
                    </vab-query-form-top-panel>
                </vab-query-form>-->
                <el-table :data="list"
                          border
                          row-key="id"
                          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                    <!--基本信息-->

                    <el-table-column show-overflow-tooltip
                                     width="200"
                                     prop="name"
                                     label="名称"></el-table-column>

                    <el-table-column show-overflow-tooltip
                                     width="80"
                                     type="index"
                                     label="序号"></el-table-column>

                    <el-table-column show-overflow-tooltip
                                     width="120"
                                     prop="longitude"
                                     label="经度"></el-table-column>

                    <el-table-column show-overflow-tooltip
                                     width="120"
                                     prop="latitude"
                                     label="纬度"></el-table-column>

                    <!--<el-table-column show-overflow-tooltip label="操作" min-width="250">
                        <template #default="{ row }">
                            <el-button type="primary" @click="handleAdd(row.id,row.name)" icon="el-icon-plus">添加城市</el-button>
                            <el-button type="info" @click="handleEdit(row)" icon="el-icon-edit-outline">编辑城市</el-button>
                        </template>
                    </el-table-column>-->
                </el-table>
            </el-col>
        </el-row>

        <edit ref="edit" @fetch-data="fetchData"></edit>
    </div>
</template>

<script>
    import { city } from '@/config/data';
    import { addCity, getCities } from '@/api/city';
    import Edit from './components/CityEdit';

    export default {
        name: 'City',
        components: { Edit },
        data() {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'label',
                },
                list: [],
                city: city
            }
        },
        async created() {
            this.fetchData();
        },
        methods: {
            async handleAdd(parentId, parentName) {
                //this.$refs['edit'].showAdd(parentId, parentName); 
            },
            handleEdit(row) {
                //this.$refs['edit'].showEdit(row);
            },
            async fetchData() {
                const { isSucceed, message, result } = await getCities();
                if (!isSucceed) return this.$baseMessage(message, 'error');
                console.log('[tree]' + JSON.stringify(result));
                this.list = result;
            },
            handleNodeClick() {
                this.fetchData();
            }
        },
    }
</script>
