<template>
    <div class="userManagement-container">
        <vab-query-form>
            <el-form :inline="true" :model="queryForm" @submit.native.prevent>
                <el-form-item>
                    <el-select v-model="queryForm.itype" placeholder="经理类型">
                        <el-option v-for="item in userItype"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="queryForm.name"
                              placeholder="请输入用户名称"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="queryForm.mobile"
                              placeholder="请输入用户手机"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" @click="queryData">
                        查询
                    </el-button>
                </el-form-item>
            </el-form>

        </vab-query-form>

        <el-table v-loading="listLoading"
                  :data="list"
                  border
                  :element-loading-text="elementLoadingText"
                  @selection-change="setSelectRows">

            <el-table-column show-overflow-tooltip
                             width="60px"
                             prop="id"
                             label="ID"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="110px"
                             prop="mobile"
                             label="用户手机">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="name"
                             label="用户姓名">
            </el-table-column>
            <el-table-column show-overflow-tooltip
                             width="150px"
                             prop="nickName"
                             label="用户昵称">
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="balance"
                             label="可用余额">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="itype"
                             label="经理类型">
                <template #default="{ row }">
                    <el-tag type="success">
                        {{ userItype.find(d=>d.value==row.itype).name }}

                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="160px"
                             prop="creationTime"
                             label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.creationTime| DateFormat}}
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="110px"
                             prop="parentMobile"
                             label="上级手机">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="parentName"
                             label="上级姓名">
            </el-table-column>
            <el-table-column show-overflow-tooltip
                             width="150px"
                             prop="parentNickName"
                             label="上级昵称">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="itype"
                             label="上级类型">
                <template #default="{ row }">
                    <el-tag type="success" v-if="row.parentItype">
                        {{ userItype.find(d=>d.value==row.parentItype).name }}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       :current-page="queryForm.pageIndex"
                       :page-size="queryForm.pageSize"
                       :layout="layout"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>

    </div>
</template>
<script>
    import { GetCusomters } from '@/api/customer';

    var enumData = require('@/config/data');
    export default {
        name: 'CusomterList',
        components: {  },
        data() {
            return {
                userItype: enumData.userItype,
                list: null,
                listLoading: false,
                layout: 'total, sizes, prev, pager, next, jumper',
                total: 0,
                selectRows: '',
                elementLoadingText: '正在加载...',
                queryForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    customerName: '',
                    customerMobile: '',
                    stated: -1,
                    itype: -1,
                },
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
           
            setSelectRows(val) {
                this.selectRows = val
            },
            handleSizeChange(val) {
                this.queryForm.pageSize = val;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.queryForm.pageIndex = val;
                this.fetchData();
            },
            queryData() {
                this.queryForm.pageIndex = 1;
                this.fetchData();
            },
            async fetchData() {
                const { isSucceed, message, result, total } = await GetCusomters(this.queryForm);
                if (!isSucceed) return this.$baseMessage(message, 'error');
                console.log(result)
                this.list = result;
                this.total = total;
            },
        },
    }
</script>