<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width=500px
               @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="上级分类">
                <label>{{form.parentName}}</label>
            </el-form-item>
            <el-form-item label="分类名称" prop="name">
                <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>           
            <el-form-item label="是否禁用">
                <el-switch v-model="form.isDisable"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { addCategory, editCategory } from '@/api/position';

    export default {
        name: 'CityEdit',
        data() {
            return {
                form: {
                    id: 0,
                    name: "",
                    isDisable: false,
                    parentId: null,
                    parentName: 'Root'
                },
                rules: {
                    name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
                },
                title: '',
                dialogFormVisible: false,
            }
        },
        created() { },
        methods: {
            showAdd(parentId, parentName) {
                this.title = '添加';
                this.form.parentId = parentId;
                this.form.parentName = parentName;
                this.dialogFormVisible = true;
            },
            showEdit(row) {
                this.title = '编辑';
                this.form = Object.assign({}, row);
                this.dialogFormVisible = true;
            },
            close() {
                this.$refs['form'].resetFields();
                this.form = this.$options.data().form;
                this.dialogFormVisible = false;
            },
            save() {
                this.$refs['form'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.id === 0) {
                        const { isSucceed, message } = await addCategory(this.form);
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    } else {
                        const { isSucceed, message } = await editCategory(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    }
                    this.$emit('fetch-data');
                })
            },
        },
    }
</script>
