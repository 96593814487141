<template>
    <div class="test-container">
        <el-divider content-position="left">This is a Test Component, Please Instead of it</el-divider>
        
    </div>
</template>
<script>
    export default {
        name: 'test',
        data() {
            return {
                show: true,
                token: 'testdsafdsafsdaf',
                firstName: 'YueXiong',
                lastName: 'Dai'
            }
        },
        computed: {
            fullName() {
                return this.firstName + this.lastName
            }
        },
        watch: {
            firstName: {
                handler(n, o) {
                    console.log(`[watch firstName] {NewValue:${n},OldValue:${o}}`);
                }
            },
            lastName: {
                handler(n, o) {
                    console.log(`[watch lastName] {NewValue:${n},OldValue:${o}}`);
                }
            }
        },
        created() {
            //console.log('[created]');
        },
        mounted() {
            //console.log('[mounted]');
        },
        methods: {},
    }
</script>